import './solana.css'
import { Link } from 'gatsby'
import RegisterButton from '../../components/solana/components/RegisterButton'
import Form from '../../components/solana/components/Form'
import FAQ from '../../components/solana/components/FAQ'
import Brands from '../../components/solana/components/Brands'

export default function Index() {
  return (
    <div id="solana">
      <main className="relative scroll-smooth bg-landing-dark min-h-full text-white min-h-100hv z-50 p-6 lg:p-10 isolate">
        <img
          className="-z-10 absolute left-0 top-0 select-none w-12rem lg:w-640px"
          src="/solana/bubble_left.png"
          alt=""
          width={500}
          height={280}
        />
        <img
          className="-z-20 absolute right-0 top-0 select-none w-30rem xl:w-50rem"
          src="/solana/bubble_right.png"
          alt=""
          width={700}
          height={300}
        />

        <Link
          className="w-fit mx-auto flex justify-center items-center"
          to="https://petitcode.com/"
        >
          <img
            className=" w-14 lg:w-32 h-auto mb-16"
            src="/solana/petitcode.svg"
            alt="petit code"
            width="95"
            height="95"
          />
        </Link>
        <div className="mb-10 lg:mb-20 flex flex-col gap-8">
          <h1 className="text-4xl lg:text-6xl text-center font-bold font-sf-bold ">
            The Solana Hackathon
          </h1>
          <h2
            style={{ color: '#CFCFCF' }}
            className="text-center text-lg lg:text-2.5rem leading-snug"
          >
            Build the next big thing. You&apos;re not just entering a <br />{' '}
            hackathon, but the beginning of your blockchain career.
          </h2>
        </div>
        <Link to="#register" className="mx-auto flex mb-20">
          <RegisterButton className="mx-auto" />
        </Link>
        <div className="mx-auto mb-20">
          <Brands />
        </div>
        <div className="mb-20" id="register">
          <Form />
        </div>
        <FAQ />
        <footer className="flex flex-col gap-5 p-5 mb-20">
          <div className="mx-auto flex gap-5 lg:gap-16">
            <Link to="https://twitter.com/petit_code" target="_blank">
              <img
                className="h-auto w-10"
                src="/solana/twitter.svg"
                alt="twitter"
                width="32"
                height="32"
              />
            </Link>
            <Link to="https://discord.gg/faAfRTZp" target="_blank">
              <img
                className="h-auto w-10"
                src="/solana/discord.svg"
                alt="twitter"
                width="32"
                height="32"
              />
            </Link>
            <Link to="https://petitcode.com/" target="_blank">
              <img
                className="h-auto w-10"
                src="/solana/web.png"
                alt="twitter"
                width="32"
                height="32"
              />
            </Link>
            <Link
              to="https://www.instagram.com/thecodetosuccess/"
              target="_blank"
            >
              <img
                className="h-auto w-10"
                src="/solana/instagram.svg"
                alt="twitter"
                width="32"
                height="32"
              />
            </Link>
          </div>
          <p className="mx-auto text-center text-lg">
            © 2023 petitcode GmbH. All rights reserved.
          </p>
          <p className="mx-auto text-center text-sm">
            <Link className="underline" to="https://petitcode.com/en/imprint">
              Imprint
            </Link>{' '}
            |{' '}
            <Link
              className="underline"
              to="https://petitcode.com/en/data-protection"
            >
              Data Protection
            </Link>
          </p>
        </footer>
      </main>
    </div>
  )
}
