export default function RegisterButton({ className, onClick }) {
  return (
    <button onClick={onClick} className={className}>
      <img
        className="w-60 lg:w-80"
        src="/solana/registerButton.svg"
        alt="register button"
        width="346"
        height="60"
      />
    </button>
  )
}
