export default function Brands() {
  return (
    <div className="flex lg:flex-row justify-center items-center gap-10 lg:gap-40">
      <img
        className="w-20 lg:w-40 h-auto"
        src="/solana/petitcode3.svg"
        alt="petitcode logo"
        width="154"
        height="58"
      />
      <img
        className="w-20 lg:w-40 h-auto"
        src="/solana/solana-foundation.svg"
        alt="solana foundation logo"
        width="115"
        height="41"
      />
    </div>
  )
}
