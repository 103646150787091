export default function FAQ() {
  return (
    <div className=" my-32 mx-auto flex flex-col justify-center items-center w-full max-w-570px lg:max-w-1218px">
      <h1 className="my-5 mt-10  font-bold blueGradiant text-4xl lg:text-6xl capitalize">
        FAQ
      </h1>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">What is Build Station?</summary>
        <div className="text-gray-200">
          <p>
            It’s an opportunity for anyone who wants to be part of Solana’s
            future and/or would like to get support to build for Grizzlython.{' '}
          </p>
          <p>
            You’ll meet and collaborate with like-minded developers & builders,
            discover new team members, and gain insights from industry leaders.{' '}
          </p>
          <p>
            The venue will be open Monday to Friday, providing comfortable
            seating spaces, meeting rooms, and free refreshments.{' '}
          </p>
          <p>
            The schedule is designed to be flexible, allowing you to attend
            talks, workshops, and even a local demo day on the last day of the
            event where you get to present your projects.
          </p>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">
          What’s in the event for me?
        </summary>
        <div className="text-gray-200">
          <p>During the event, you can expect to:</p>
          <ul className="list-disc p-6">
            <li>
              Learn educational content on Solana, DeFi, NFTs, and more through
              workshops, panels, and keynotes from partners.
            </li>
            <li>
              Get hands-on experience by working on your project, meeting new
              people, finding team members, or joining an existing team.
            </li>
            <li>
              Receive support and advice from Solana experts, and showcase your
              work at a demo day on the last day of the event.
            </li>
            <li>
              Network and connect with other builders and enthusiasts in the
              ecosystem.
            </li>
            <li>
              Enjoy a comfortable venue equipped with good Wi-Fi, workstations,
              as well as free drinks & snacks, and relaxation lounges.
            </li>
            <li>Participate in exclusive evening events.</li>
          </ul>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">Who can participate?</summary>
        <div className="text-gray-200">
          <p>
            The Station is open to everyone interested in Solana – and you don’t
            have to be German. It doesn’t matter if you’re a developer, biz-dev,
            artist, marketer, etc. You may be just starting out, or a seasoned
            pro, there&apos;s still a place for you there.
          </p>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">
          Where is Build Station taking place?
        </summary>
        <div className="text-gray-200">
          <p>In the heart of Berlin, at the W3 Hub. Möckernstraße 120.</p>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">
          How much does it cost to participate?
        </summary>
        <div className="text-gray-200">
          <p>
            Nothing – attendance is free. But make sure to register here
            beforehand, because the venue has limited capacity.
          </p>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">
          How will the winners of Build Station be selected?
        </summary>
        <div className="text-gray-200">
          <p>
            On the last day of the event (March 15) there will be a local demo
            where participants get to present their projects. The demonstration
            will be streamed live to a global audience. To qualify for the
            Berlin Demo Day, you’ll need to:
          </p>
          <ul className="list-disc p-6">
            <li>
              Apply for Solana Grizzlython{' '}
              <a className="underline" href="https://solana.com/grizzlython">
                here.
              </a>{' '}
              Make sure to mark “Germany” in the Grizzlython application form to
              be eligible for Berlin Demo Day prizes.
            </li>
            <li>
              Fill out the Berlin Demo Day form (it will be available closer to
              the Demo Day date).
            </li>
            <li>
              Showtime! Join us in real life at the Berlin Build Station and
              pitch your hackathon submission on stage!
            </li>
          </ul>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">
          What are the prizes of the Build Station Demo Day?
        </summary>
        <div className="text-gray-200">
          <p>
            Grizzlython submissions from Germany qualify for a chance to be
            awarded exclusive prizes at the Berlin Demo Day, in addition to the
            Grizzlython prizes. The winners will be selected by a group of
            judges, made up by Solana Foundation, Superteam and ecosystem
            partners.
          </p>
          <div>
            <p>Superteam Germany Grand Prizes:</p>
            <ul className="list-disc px-6">
              <li>1st Place: $7000</li>
              <li>2nd Place: $5000</li>
              <li>3rd Place: $3000</li>
            </ul>
          </div>
          <div>
            <p>Ecosystem Partner Grand Prizes:</p>
            <p>
              Each Partner awards their prize to the best project building for
              their specific local track. More details on the exact tracks
              coming soon.
            </p>
            <ul className="list-disc px-6">
              <li>Elusiv :: $5000</li>
              <li>Staking Facilities :: $5000</li>
              <li>H.E.R. DAO :: $2000</li>
              <li>OpenBook :: $2000</li>
              <li>Spling Labs :: $2000</li>
              <li>Greenfield Capital :: $1000</li>
              <li>Mango Markets :: $1000</li>
            </ul>
          </div>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">
          Is it possible to get mentorship virtually?
        </summary>
        <div className="text-gray-200">
          <p>
            Build Station channels are open to all on our{' '}
            <a className="underline" href="https://discord.gg/TwW4WTPxfu">
              Discord
            </a>
            , and you can always reach out to us for more specific help.{' '}
          </p>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">Can I bring my dog?</summary>
        <div className="text-gray-200">
          <p>Yes, but only if we can pet them.</p>
        </div>
      </details>
      <details className="rounded-2xl bg-landing-form-control-bg my-3">
        <summary className="list-none text-3xl">Did we miss something?</summary>
        <div className="text-gray-200">
          <p>DM @BSPBerlin on Telegram.</p>
        </div>
      </details>
    </div>
  )
}
