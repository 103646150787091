// @ts-ignore
import HubspotForm from 'react-hubspot-form'

export default function Form() {
  const submit = () => {
    const hubSpotSubmitButton = document.getElementsByClassName('hs-button')[0] // @ts-ignore

    console.log(hubSpotSubmitButton)

    hubSpotSubmitButton.click()
  }

  return (
    <div>
      <div className="flex flex-col gap-10 justify-center items-center">
        <h1 className="text-4xl lg:text-6xl text-center font-bold font-sf-bold">
          Register <span className="blueGradiant">now</span>
        </h1>
        <div className=" my-5 mx-auto flex flex-col justify-center items-center w-full max-w-570px lg:max-w-1218px text-2xl">
          <p className="text-center">
            Build Station is a co-working space based in Berlin with the mission
            to support teams and projects building for the worldwide hackathon{' '}
            <strong>&quot;Solana Grizzlython&quot;</strong>. The event is
            promoted by Superteam Germany, and anyone interested in Solana can
            comfortably learn, network, and build in person for free during a
            whole month.
          </p>
        </div>
        <div className="flex flex-col gap-10 lg:flex-row-reverse">
          <div className="relative rounded aspect-square">
            <img
              className="h-auto"
              src="/solana/hackathon.png"
              alt="build station berlin"
              width="570"
              height="570"
            />
          </div>
          <div className="">
            <HubspotForm
              portalId="3948482"
              formId="08590030-6cf2-4760-ab88-94755582782a"
              onSubmit={() => console.log('Submit!')}
              loading={<div>loading form...</div>}
            />
            <div className="flex justify-center items-center lg:justify-start">
              <button onClick={submit} type="submit" value="Submit Now">
                <img
                  className="w-60 lg:w-80"
                  src="/solana/submit.svg"
                  alt="submit button"
                  width="346"
                  height="60"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
